<template>
  <div>
    <!-- 弹窗form -->
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false">
      <!-- 表单 -->
      <DrawerComponent
        :button="drawerButton"
        @colse="formShow = false"
        :loading="loading"
        :drawerConfig="drawerConfig"
        @save="requestSave"></DrawerComponent>
      <!-- 执行人列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="选择执行人"
          size="80%"
          :visible.sync="drawerShowCustomer"
          :modal="false"
          :append-to-body="true">
          <div class="drawer-table" >
            <TableComponent @searchData="searchCustomer"
                            :pagination="tablePaginationCustomer"
                            :templateData="tableDataCustomer"
                            :table-loading="tableLoadingCustomer"
                            :searchConfig="searchConfigCustomer"
                            :table-config="tableConfigCustomer"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect" :btnType="'primary'" :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowCustomer = false" :btnType="'danger'" :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>
    <DrawerComponent
      v-if="dmsId"
      :button="drawerButton"
      @colse="formShow = false"
      :loading="loading"
      :drawerConfig="drawerConfig"
      @save="requestSave"></DrawerComponent>
  </div>

</template>

<script>
import buttonComponent from '../../../../compoment/button-component/button-component';
import TableComponent from '../../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  props: ['dmsId', 'dmsCode'],
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      id: '',
      code: '',
      loading: false,
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: () => {
              this.requestSave(undefined, 'Y');
            },
          },
        ],
      },
      drawerConfig: [ // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: { orderSourceType: 'back' },
          formConfig: { // 弹窗表单配置
            items: [
              [{
                label: '单据编号',
                key: 'billCode',
                show: false,
              }, {
                label: '负责人',
                key: 'responsibleName',
                disabled: true,
              }, {
                label: '执行人',
                key: 'executorName',
                valueType: 'iconInput',
                icon: 'el-icon-circle-plus-outline',
                fn: () => {
                  this.searchCustomer({});
                },
              }, {
                label: '来源',
                key: 'source',
                valueType: 'select',
                path: 'code?dictTypeCode=sale_fake_source',
              }, {
                valueType: 'select',
                label: '案件状态',
                key: 'caseStatus',
                path: 'code?dictTypeCode=sale_fake_case_status',
              }, {
                label: '状态',
                key: 'status',
                show: false,
              }, {
                label: '反馈日期',
                key: 'feedbackTime',
                valueType: 'date',
              }, {
                label: '案件描述',
                key: 'caseDescribe',
                valueType: 'textarea',
                itemWidth: '100%',
              }, {
                label: '市场调查反馈',
                key: 'marketSurveyFeedback',
                valueType: 'textarea',
                itemWidth: '100%',
              }, {
                key: 'remarks',
                valueType: 'textarea',
                label: '备注',
                itemWidth: '100%',
              }, {
                valueType: 'textarea',
                key: 'note',
                label: '注释',
                itemWidth: '100%',
              }, {
                label: '附件',
                key: 'attach',
                itemWidth: '100%',
                valueType: 'inputUpload',
                uploadConfig: {
                  accept: 'pdf,docx,xlsx,ppt,png,jpg',
                },
              }, {
                valueType: 'text',
                text: '执行结果',
                labelWidth: '0',
                itemWidth: '100%',
              }, {
                valueType: 'textarea',
                key: 'caseProgress',
                label: '案件进展',
                itemWidth: '100%',
              }, {
                valueType: 'textarea',
                key: 'implementSituation',
                label: '执行情况',
                itemWidth: '100%',
              }, {
                valueType: 'textarea',
                key: 'implementResult',
                label: '执行结果',
                itemWidth: '100%',
              }],
            ],
          },
          rules: {
            executorName: [
              {
                required: true,
                message: '请选择执行人',
                trigger: 'change',
              },
            ],
            source: [
              {
                required: true,
                message: '请选择执行人',
                trigger: 'change',
              },
            ],
            caseStatus: [
              {
                required: true,
                message: '请选择案件状态',
                trigger: 'change',
              },
            ],
            caseDescribe: [
              {
                required: true,
                message: '请选择案件描述',
                trigger: 'change',
              },
            ],
          },
        },
      ],

      //  ----------------------- 弹窗 - 执行人列表配置
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '姓名',
            prop: 'fullName',
          },
          {
            label: '角色',
            prop: 'roleName',
          }, {
            label: '所属组织',
            prop: 'positionName',
          },
        ],
      },
      searchConfigCustomer: {
        options: [
          {
            valueName: 'fullName',
            placeholder: '请输入执行人名称',
          },
        ],
      },
      tablePaginationCustomer: {},
    };
  },

  created() {
    this.initPageState();

    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    this.id = this.dmsId || this.id;
  },
  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },
  methods: {
    initPageState() {
      const itemFormNames = ['status', 'billCode'];
      this.setPeople();
      if (this.code === 'view') { // 设置查看显示的字段
        this.setFormItemValue(itemFormNames, 'show', true);
      } else {
        this.setFormItemValue(itemFormNames, 'show', false);
      }

      if (this.code !== 'add') { // 非新增查询详情
        this.searchDetail();
      }
    },

    /**
     * 设置负责人相关信息
     */
    setPeople() {
      this.setFormData(this.userInfo.fullName, 'responsibleName');
      this.setFormData(this.userInfo.userCode, 'responsibleCode');
    },

    /**
     * 查询执行人
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData) {
      this.showDrawer('drawerShowCustomer');
      this.tableLoadingCustomer = true;
      const params = { pageSize: 10, customerType: 'u', ...requestData };
      const { result } = await this.$http.post(
        '/mdm/mdmUserExtController/pageInfoList', params,
      );
      this.tablePaginationCustomer.total = result.count;
      this.tableDataCustomer = result.data;
      this.tableLoadingCustomer = false;
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.drawerConfig[0].formConfig.disabled = state;
      this.drawerButton.show = !state;
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id) {
        const { result } = await this.$http.post('/dms/saleFake/getDetail', { id: this.id }, {
          headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        });
        this.drawerConfig[0].ruleForm = { ...result };
        this.setDisabled(this.code === 'view');
      }
    },

    /**
     * 查询负责人
     * @param customerCode {string}
     * @returns {Promise<void>}
     * */
    async searchDocking(customerCode) {
      const { result } = await this.$http.post('/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCode', { customerCode }, {
        headers: {
          ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      });
      this.setFormItemValue(['ext14'], 'data', result);
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      this.selectTableData = data;
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this[name] = false;
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>}
     * @param state {boolean}
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      if (this.drawerShowCustomer === true && !this.selectMessage()) {
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存执行人
     */
    saveSelectCustomer() {
      this.setFormData(this.selectTableData[0].userCode, 'executorCode');
      this.setFormData(this.selectTableData[0].fullName, 'executorName');
    },

    /**
     * 提交表单数据
     * @param data {Object}
     */
    async requestSave(data = this.drawerConfig[0].ruleForm, temporaryFlag) {
      this.loading = true;
      const url = this.code === 'edit' ? '/dms/saleFake/updateSaleFake' : '/dms/saleFake/saveSaleFake';
      data.temporaryFlag = temporaryFlag || 'N';
      try {
        await this.$http.post(url, data);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },

    /**
     * 查询父组件列表
     * */
    // getParentTable() {
    //
    // },

    /**
     * 设置表单数据
     * @param data {}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data {<void>}
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.forEach((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button{
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #EBEEF5;
}
.drawer-table {
  padding:20px;
  margin-bottom: 100px;
}
.slot-body{
  display: flex;
  div {
    display: flex;
    align-items: center;
    flex-grow: 2;
    span{
      flex-shrink: 0;
      margin-right: 5px;
    }
    input{
      width: 150px;
    }
  }
}
</style>
